<template>
    <div class="calendar-page">
        <div class="container-fluid">
            <h1>Kalendarz</h1>
            <font-awesome-icon v-if="isLoading" :icon="['fas', 'spinner']" class="fa-spin buttonFaSpin" />
            <div v-else class="row" >
                <div class="col-12">
                    <monthCalendar :startDate="currentMonth" @changeMonth="changeMonth" @selectDay="selectDay">
                        <template v-slot:dayContent="{currentDate}" > 
                            <div class="d-md-flex justify-content-between align-items-start overflow-hiddden" v-html="dayContent( currentDate )"></div>
                        </template>
                    </monthCalendar>
                </div>
                <div class="col-12" v-if="selectedDate">
                    <font-awesome-icon v-if="isLoadingDate" :icon="['fas', 'spinner']" class="fa-spin buttonFaSpin" />
                    <div v-else>
                        Wybrana data: {{ selectedDate.format("YYYY-MM-DD") }}
                        <div v-if="dayData">
                            <div class="m-3">
                            <b-button-group>
                            <b-button variant="secondary" :to="{name: 'adminDeliveriesList',  params: { day: (selectedDate.format('YYYY-MM-DD')) } }" >Dostawy</b-button>
                            <b-button variant="secondary" :to="{name: 'adminDeliveriesListProducts',  params: { day: (selectedDate.format('YYYY-MM-DD')) } }" >Produkty</b-button>
                            </b-button-group>
                            </div>
                            <b-table :items="dayData" :fields="fields" :show-empty="true" class="mt-2" striped  outlined sort-icon-left empty-filtered-text="Nie znaleziono" empty-text="Brak danych">
                            </b-table>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
axios.defaults.headers.common['Accept'] = 'application/json';

import monthCalendar from '@/components/assets/MonthCalendar';
import moment from 'moment';

export default {
    name: 'adminCalendar',
    components: {
       monthCalendar
    },
    data() {
        return {
            isLoading: true,
            isLoadingDate: false,
            filter: [],
            monthData: [],
            dayData: [],
            currentMonth: moment(new Date()),
            selectDateList: [],
            selectedDate: false,
            fields: [
                { label: 'Zamówienie', key: 'schedule.id', sortable: true },
                { label: 'Produkt', key: 'product.name', sortable: true },
                { label: 'Ilość', key: 'amount', sortable: true },
                { label: 'Status', key: 'statusname', sortable: false }
            ]
        }
    },
    mounted(){
        this.loadMonth();
    },
    computed: {
    },
    methods: {
        dayContent( date ) {
            let dateStr = date.moment.format("Y-MM-DD");
            const dayData = this.monthData.find( item => item.date == dateStr );
            if ( dayData && dayData.count > 0 ) {
                return ''
                    + '<span class="badge badge-default" data-toggle="tooltip" title="Produkty"> ' + dayData.products + '</span>'
                    + '<span class="badge ' + (dayData.new>0 ? 'badge-warning' : 'badge-default') + '" data-toggle="tooltip" title="Nowe - do zapłaty"> ' + dayData.new + '</span>'
                    + '<span class="badge ' + (dayData.paid>0 ? 'badge-success' : 'badge-default') + '" data-toggle="tooltip" title="Opłacone"> ' + dayData.paid + '</span>'
                    + '<span class="badge badge-default" data-toggle="tooltip" title="Dostarczone">' + dayData.delivered + '</span>'
                    + '<span class="badge badge-secondary" data-toggle="tooltip" title="Anulowane">' + dayData.canceled + '</span>'
                    + '<span class="badge ' + (dayData.failed>0 ? 'badge-danger' : 'badge-default') + '" data-toggle="tooltip" title="Nie da się dostarczyć">' + dayData.failed + '</span>'
            } else {
                return '';
            }
        },
        selectDay(moment){
            // this.selectedDate = moment.format("Y-MM-DD");
            this.selectedDate = moment;
            this.isLoadingDate =  true;
            this.dayData = [];
            if(this.selectedDate) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
                axios.get('/admin/calendar/day/' + this.selectedDate.format('YYYY/MM/DD')).then( (responce) => {
                    this.isLoadingDate =  false;
                    this.dayData = responce.data;
                }).catch( () => {
                    this.isLoadingDate = false;
                    this.$bvToast.toast(`Błąd pobierania danych`, {
                        title: 'Błąd',
                        autoHideDelay: 5000,
                        solid: true,
                        variant: 'danger',
                        appendToast: true,
                        toaster: 'b-toaster-top-right'
                    })
                });
            }

        },
        changeMonth(date){
            this.currentMonth = date;
            this.loadMonth();
        },
        loadMonth(){
            this.isLoading =  true;
            this.monthData = [];
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$store.getters['auth/token'];
            axios.get('/admin/calendar/month/' + this.currentMonth.format("Y/M")).then( (responce) => {
                this.isLoading =  false;
                this.monthData = responce.data;
            }).catch( () => {
                this.isLoading = false;
                this.$bvToast.toast(`Błąd pobierania danych`, {
                    title: 'Błąd',
                    autoHideDelay: 5000,
                    solid: true,
                    variant: 'danger',
                    appendToast: true,
                    toaster: 'b-toaster-top-right'
                })
            });
        }
    }
}
</script>
